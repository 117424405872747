.ant-input, .ant-select, .ant-select-selector {
    border-color: #ccc !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
}

.btn, .btn:hover, .btn:focus, .btn-check:checked+.btn, .dual-listbox .btn-check:checked+.dual-listbox__button, .btn-check:active+.btn, .dual-listbox .btn-check:active+.dual-listbox__button, .btn:active, .dual-listbox .dual-listbox__button:active, .btn.active, .dual-listbox .active.dual-listbox__button, .btn.show, .dual-listbox .show.dual-listbox__button {
    border: none !important;
}

.search-content .form-control {
    border: none !important;
}

.add-website .col-lg-12 {
    margin-top: 15px;
}

.nk-header-fixed, .nk-sidebar {
    z-index: 1000;
}

/* purgecss start ignore */
@tailwind base;
@tailwind components;
@use url('https://use.typekit.net/mpa1wkh.css');

/* html,
body {
    font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    color: rgb(75, 75, 75);
    background: #e0e0e0;
} */

/* purgecss end ignore */

@tailwind utilities;

.component-selected {
    @apply relative;
}

.component-selected::after {
    content: ' ';
    @apply border-primary border border-dashed w-full h-full absolute left-0 top-0 pointer-events-none block;
}

.transition {
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

#carbonads * {
    margin: initial;
    padding: initial;
}

#carbonads {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
        sans-serif;

    padding: 10px 0.5rem;
    border-top: 1px solid rgb(0 0 0 / 6%);
}